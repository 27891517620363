import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ClassificationStatusEnum } from '../model/common/classification-status.enum';
import { Contact } from '../model/common/contact.model';
import { GenericResponse } from '../model/common/generic-response';
import { InteractionData } from '../model/common/interaction-data.model';
import { Interaction } from '../model/common/interaction.model';
import { CheckConnectionDto } from '../model/sdr-conversations/check-connection.dto';
import { ClassifyMessageDto } from '../model/sdr-conversations/classify-message.dto';
import { ClassifyTrashMessagesDto } from '../model/sdr-conversations/classify-trash-messages.dto';
import { Conversation, Message, SdrConversation } from '../model/sdr-conversations/sdrs-conversations.model';
import { SendMessageDto } from '../model/sdr-conversations/send-message.dto';

@Injectable({
  providedIn: 'root',
})
export class SdrConversationsService {
  private reloadConversationsSubject = new BehaviorSubject<boolean>(false);
  private sdrsConversationsSubject = new BehaviorSubject<SdrConversation[]>(null);
  private selectedConversationSubject = new BehaviorSubject<Conversation>(null);
  private selectedInteractionSubject = new BehaviorSubject<Interaction>(null);
  private selectedMessagesSubject = new BehaviorSubject<Message[]>(null);
  private currentContactSubject = new BehaviorSubject<Contact>(null);
  private hasInteractionsSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  get sdrsConversations$() {
    return this.sdrsConversationsSubject.asObservable();
  }

  setReloadConversations(value: boolean) {
    this.reloadConversationsSubject.next(value);
  }

  get reloadConversations$() {
    return this.reloadConversationsSubject.asObservable();
  }

  setSdrsConversations(sdrsConversations: SdrConversation[]) {
    this.sdrsConversationsSubject.next(sdrsConversations);
  }

  get selectedConversation$() {
    return this.selectedConversationSubject.asObservable();
  }

  setSelectedConversation(conversation: Conversation) {
    this.selectedConversationSubject.next(conversation);
  }

  get hasInteractions$() {
    return this.hasInteractionsSubject.asObservable();
  }

  setHasInteractions(hasInteractions: boolean) {
    this.hasInteractionsSubject.next(hasInteractions);
  }

  get selectedInteraction$() {
    return this.selectedInteractionSubject.asObservable();
  }
  setSelectedInteraction(interaction: Interaction) {
    this.selectedInteractionSubject.next(interaction);
  }

  get selectedMessages$() {
    return this.selectedMessagesSubject.asObservable();
  }

  setSelectedMessages(message: Message[]) {
    this.selectedMessagesSubject.next(message);
  }

  get currrentContact$() {
    return this.currentContactSubject.asObservable();
  }

  setCurrentContact(contact: Contact) {
    this.currentContactSubject.next(contact);
  }

  checkConnection(checkConnectionDto: CheckConnectionDto): Observable<boolean> {
    const url = `${environment.newApiBaseUrl}/messages/check-connection`;

    return this.http.post(url, checkConnectionDto).pipe(map((response: GenericResponse<boolean>) => response.data));
  }

  classifyTrashMessages(classifyTrashMessagesDto: ClassifyTrashMessagesDto) {
    const url = `${environment.newApiBaseUrl}/messages/classify-trash-messages`;

    return this.http.post(url, classifyTrashMessagesDto);
  }

  classifyMessage(classifyMessageDto: ClassifyMessageDto): Observable<GenericResponse<any>> {
    const url = `${environment.newApiBaseUrl}/messages/classify-message`;

    return this.http.post<GenericResponse<any>>(url, classifyMessageDto);
  }

  getSdrConversations(): Observable<SdrConversation[]> {
    const url = `${environment.newApiBaseUrl}/messages/messages-feed`;

    return this.http.get(url).pipe(
      map((response: GenericResponse<SdrConversation[]>) =>
        response.data.map(
          (sdr) =>
            ({
              sdrId: sdr.sdrId,
              sdrName: sdr.sdrName,
              sdrEmail: sdr.sdrEmail,
              conversations: sdr.conversations.map((conversation) => ({
                ...conversation,
                sdrId: sdr.sdrId,
                sdrName: sdr.sdrName,
                sdrEmail: sdr.sdrEmail,
              })),
            }) as SdrConversation,
        ),
      ),
      tap((sdrConversations: SdrConversation[]) => {
        this.setSdrsConversations(sdrConversations);
      }),
    );
  }

  getSdrInteractions(sdrId: number, conversationId: string): Observable<InteractionData> {
    const url = `${environment.newApiBaseUrl}/messages/interaction-data/${sdrId}/${conversationId}`;

    return this.http.get(url).pipe(map((response: GenericResponse<InteractionData>) => response.data));
  }

  sendMessage(sendMessageDto: SendMessageDto): Observable<Object> {
    const url = `${environment.newApiBaseUrl}/messages/send-message`;

    return this.http.post(url, sendMessageDto);
  }

  addMessageToConversation(newMessage: Message): void {
    const currentConversations = this.sdrsConversationsSubject.getValue();
    const { sdrId, conversationId } = this.selectedConversationSubject.getValue();

    const updatedConversations = currentConversations.map((sdr) => {
      if (sdr.sdrId === sdrId) {
        return {
          ...sdr,
          conversations: sdr.conversations.map((conversation) => {
            if (conversation.conversationId === conversationId) {
              return {
                ...conversation,
                messages: [...conversation.messages, newMessage],
              };
            }
            return conversation;
          }),
        };
      }
      return sdr;
    });

    this.sdrsConversationsSubject.next(updatedConversations);

    this.updateSelectedConversation(updatedConversations);
  }

  updateSelectedConversation(updatedSdrConversations: SdrConversation[]) {
    const { sdrId, conversationId } = this.selectedConversationSubject.getValue();
    const updatedSelectedConversation = updatedSdrConversations
      .find((sdr) => sdr.sdrId === sdrId)
      ?.conversations.find((conv) => conv.conversationId === conversationId);

    this.selectedConversationSubject.next(updatedSelectedConversation || null);
  }

  updateConversation(classificationName?: string) {
    const currentConversations = this.sdrsConversationsSubject.getValue();
    const { sdrId, conversationId, messages } = this.selectedConversationSubject.getValue();

    if (!this.selectedMessagesSubject.getValue()?.length) {
      return;
    }

    const messageHandlerIds =
      classificationName === ClassificationStatusEnum.Converted
        ? []
        : this.selectedMessagesSubject.getValue().map((m) => m.messageHandlerId);

    classificationName === ClassificationStatusEnum.Converted &&
      messages.forEach((message) => (message.messageStatus = ClassificationStatusEnum.Converted));

    const filteredMessages = messages?.filter((message) => !messageHandlerIds?.includes(message.messageHandlerId));
    const hasInboundMessages = filteredMessages.some((message) => message.isInbound === true && !message?.isTemp);

    if (hasInboundMessages) {
      const updatedSdrConversations = currentConversations.map((sdrConversation) => {
        if (sdrConversation.sdrId === sdrId) {
          const updatedConversations = sdrConversation.conversations.map((conversation) => {
            if (conversation.conversationId === conversationId) {
              return { ...conversation, messages: filteredMessages };
            }
            return conversation;
          });

          return { ...sdrConversation, conversations: updatedConversations };
        }
        return sdrConversation;
      });
      this.sdrsConversationsSubject.next(updatedSdrConversations);

      this.updateSelectedConversation(updatedSdrConversations);
    } else {
      this.reloadConversationsSubject.next(true);
    }
  }
}
