import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ClassificationStatusEnum } from '../../../model/common/classification-status.enum';
import { InteractionData } from '../../../model/common/interaction-data.model';
import { Interaction } from '../../../model/common/interaction.model';
import { Conversation } from '../../../model/sdr-conversations/sdrs-conversations.model';
import { ConfigurationService } from '../../../services/configuration.service';
import { InteractionService } from '../../../services/interaction.service';
import { SdrConversationsService } from '../../../services/sdr-conversations.service';
import { UndoInteractionDialogComponent } from '../../shared/undo-interaction-dialog/undo-interaction-dialog.component';

@Component({
  selector: 'app-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss'],
})
export class InteractionsComponent implements OnInit, OnDestroy {
  interactionsData: InteractionData;
  selectedConversation: Conversation;
  selectedInteraction: Interaction;
  isLoadingInteractions = false;
  isMobileOrTablet = false;
  classificationStatus = ClassificationStatusEnum;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private dialog: MatDialog,
    private interactionService: InteractionService,
    private sdrConversationsService: SdrConversationsService,
    private configurationService: ConfigurationService,
    private snackBar: MatSnackBar,
  ) {
    inject(BreakpointObserver)
      .observe(['(max-width: 959px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          this.isMobileOrTablet = result.breakpoints[query];
        }
      });
  }

  ngOnInit(): void {
    this.sdrConversationsService.selectedConversation$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedConversation: Conversation) => {
        this.selectedConversation = selectedConversation;
        this.interactionsData = null;

        if (selectedConversation) {
          const { sdrId, conversationId } = selectedConversation;
          this.loadSdrInteractions(sdrId, conversationId);
          this.getClientRepresentatives(sdrId);
        }
      },
    });

    this.interactionService.interactionsUpdated$.subscribe((updated) => {
      if (updated) {
        this.loadSdrInteractions();
      }
    });

    this.sdrConversationsService.selectedInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.selectedInteraction = interaction;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadSdrInteractions(sdrId?: number, conversationId?: string) {
    const currentSdrId = sdrId || this.selectedConversation?.sdrId;
    const currentConversationId = conversationId || this.selectedConversation?.conversationId;
    this.isLoadingInteractions = true;

    if (currentSdrId && currentConversationId) {
      this.sdrConversationsService
        .getSdrInteractions(currentSdrId, currentConversationId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.isLoadingInteractions = false;
            this.interactionService.setInteractionsUpdated(false);
          }),
        )
        .subscribe({
          next: (response: InteractionData) => {
            this.interactionsData = response;

            const mostRecentSentInteraction = this.interactionsData?.interactions
              .filter((interaction) => interaction?.prospectStatus === 'Sent')
              .sort(
                (a, b) => new Date(b?.utcDatetimeInteraction).getTime() - new Date(a?.utcDatetimeInteraction).getTime(),
              )[0];

            this.setCurrentInteraction(mostRecentSentInteraction || null);
            this.sdrConversationsService.setHasInteractions(this.interactionsData?.interactions?.length ? true : false);
            this.sdrConversationsService.setCurrentContact(this.interactionsData?.contact || null);
          },
          error: () => {
            this.snackBar.open('An error occurred while retrieving conversations', null, {
              duration: 3000,
            });
            this.sdrConversationsService.setHasInteractions(false);
          },
        });
    }
  }

  getClientRepresentatives(sdrId: number) {
    this.configurationService.getClientRepresentatives(sdrId);
  }

  setCurrentInteraction(interaction: Interaction) {
    this.sdrConversationsService.setSelectedInteraction(interaction);
  }

  onClickUndoInteraction(interaction: Interaction) {
    this.dialog.open(UndoInteractionDialogComponent, {
      width: '30%',
      maxWidth: 'initial',
      data: {
        interactionId: interaction.interactionId,
        customerId: interaction.customerId,
        fromMail: '',
        prospectStatusId: interaction.prospectStatusId,
      },
    });
  }
}
